import React from 'react';

import { COLORS } from '@global/styles/colors';

import { Markdown } from '@components/StrapiComponents';
import { Background, ButtonContainer, Arrow, LinkContainer } from './Button.styles';
import { IProps } from './Button.types';

import whiteArrow from './assets/whiteArrow.svg';

const Button = ({
  colors,
  disabled,
  text,
  onClick,
  active,
  dropdownButton,
  width,
  type,
  whiteBackground,
}: IProps) => {
  return (
    <Background>
      {typeof onClick === 'string' ? (
        <LinkContainer
          target={onClick[0] !== '/' ? '_blank' : '_self'}
          type={type || 'button'}
          isActive={!!active}
          color={COLORS[colors[0]] || colors[0]}
          activeColor={COLORS[colors[1]] || colors[1]}
          href={onClick}
          width={width}
          whiteBackground={whiteBackground}
        >
          <Markdown listPointColor="white" data={{ content: text }} />
          {dropdownButton && (
            <Arrow src={whiteArrow} isActive={!!active} dropdownButton={dropdownButton} />
          )}
        </LinkContainer>
      ) : (
        <ButtonContainer
          type={type || 'button'}
          isActive={!!active}
          color={COLORS[colors[0]] || colors[0]}
          activeColor={COLORS[colors[1]] || colors[1]}
          onClick={onClick}
          width={width}
          disabled={disabled}
          whiteBackground={whiteBackground}
        >
          <Markdown listPointColor="white" data={{ content: text }} />
          {dropdownButton && (
            <Arrow src={whiteArrow} isActive={!!active} dropdownButton={dropdownButton} />
          )}
        </ButtonContainer>
      )}
    </Background>
  );
};

export default Button;
