import React from 'react';
import MarkdownView from 'react-showdown';

import { COLORS } from '@global/styles/colors';

import { MarkdownProps } from './markdownTitle.types';

import { MarkdownStyles } from './markdownTitle.styles';

export const singleLineExtension = {
  type: 'lang',
  regex: /\n\n/g,
  replace: ' ',
};
export const strikethroughExtension = {
  type: 'lang',
  regex: /~(.*)~/g,
  replace: '<s>$1</s>',
};

export const spanNowrapFormatExtension = {
  type: 'lang',
  regex: /\[\[(.*?)\]\]/g,
  replace: '<span class="nospace">$1</span>',
};

const MarkdownParser = ({ data, listPointColor, color }: MarkdownProps) => {
  return (
    <MarkdownStyles
      listPointColor={COLORS[listPointColor] || listPointColor}
      color={COLORS[color] || color}
    >
      <MarkdownView
        markdown={data.content}
        extensions={[singleLineExtension, strikethroughExtension, spanNowrapFormatExtension]}
      />
    </MarkdownStyles>
  );
};

export default MarkdownParser;
