import styled from 'styled-components';

import { Field, Form } from 'formik';

import { FONT } from '@global/styles/default';
import { COLORS } from '@global/styles/colors';
import { TABLET_800, TABLET_600, MOBILE_575 } from '@global/styles/sizes';

export const ModalContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: gray;
  height: 100vh;
`;

export const Header = styled.div`
  width: 100%;
  height: 73px;
  position: relative;
  background: transparent linear-gradient(271deg, #009144 0%, #00a6cb 100%) 0% 0% no-repeat
    padding-box;
  border-radius: 24px 24px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLORS.white};
  margin-bottom: 20px;
  //font: normal normal bold 26px/32px;
  font-size: 26px;
  font: normal normal bold 26px ${FONT.centuryGothic};

  @media (max-width: 400px) {
    font-size: 18px;
  }
`;

export const Container = styled.div`
  width: 100%;
  overflow-y: scroll;
  max-height: 90vh;
  max-width: 714px;
  min-width: fit-content;
  //box-shadow: 0px 3px 6px ${COLORS.boxShadow};
  border-radius: 24px;
  background-color: white;
  position: relative;
  z-index: 4;
  padding-bottom: 26px;

  @media (max-width: ${TABLET_800}px) {
    max-width: 380px;
    height: 700px;
  }
  @media (max-width: 400px) {
    max-width: 300px;
    height: 700px;
  }
`;

export const Cross = styled.img`
  position: absolute;
  top: 21px;
  right: 21px;
  cursor: pointer;
`;

export const FormikForm = styled(Form)`
  display: flex;
  flex-direction: column;
  margin-top: 28px;

  @media (max-width: ${TABLET_800}px) {
    padding: 0 18px;
  }
`;

export const RowInputs = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: ${TABLET_800}px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const Input = styled(Field)<IInputProps>`
  width: ${(props) => (props.lessWidth ? '45%' : '99%')};
  height: 31px;
  margin-top: 10px;
  background: ${COLORS.white};
  box-shadow: 0px 0px 4px #0000001d;
  border-radius: 5px;
  border: 1px solid transparent;
  color: ${COLORS.vampireBlack};
  outline: none;
  position: relative;
  font: normal normal normal 12px/12px ${FONT.centuryGothic};

  :focus {
    border: 1px solid ${COLORS.richElectricBlue};
    color: ${COLORS.vampireBlack};
  }

  ::placeholder {
    color: ${COLORS.grayForm};
  }

  @media (max-width: ${TABLET_800}px) {
    width: 101%;
  }
`;

export const Text = styled.div<IInputProps>`
  color: ${COLORS.vampireBlack};
  text-align: ${(props) => (props.alignLeft ? 'start' : 'center')};
  margin-top: ${(props) => props.marginTop && props.marginTop};
  font: normal normal normal 16px/26px ${FONT.centuryGothic};
  @media (max-width: ${MOBILE_575}px) {
    margin: 0 auto;
    max-width: 80%;
  }
  a {
    color: ${COLORS.vampireBlack};
  }
`;

export const ButtonContainer = styled.div<IInputProps>`
  display: flex;
  margin: auto;
  margin: 21px auto;
  cursor: auto !important;

  div {
    opacity: ${(props) => (props.hasErrors ? '0.5' : '1')};
    cursor: ${(props) => props.hasErrors && 'auto !important'};

    button {
      cursor: auto !important;
    }
  }
`;

export const FormContainer = styled.div`
  max-height: 100%;
  max-width: 528px;
  margin: 0 auto;
  width: 100%;
  position: relative;
  z-index: 2;
  //overflow-y: scroll;

  @media (max-width: ${TABLET_600}px) {
    max-width: 400px;
    //padding: 0 15px;
    //padding: 0 20px;
  }
  @media (max-width: 400px) {
    max-width: 290px;
  }
`;

export const LineTop = styled.img`
  position: absolute;
  left: 0;
  top: 73px;

  @media (max-width: ${TABLET_800}px) {
    display: none;
  }
`;

export const LineBottom = styled.img`
  position: absolute;
  right: -17%;
  bottom: -8%;
  height: 35%;
  z-index: 1;

  @media (max-width: ${TABLET_800}px) {
    display: none;
  }
`;

export const ErrorMessage = styled.div`
  color: red;
  font-size: 13px;
  margin-top: 7px;
  font: normal normal normal 12px/20px ${FONT.centuryGothic};
`;

export const TextArea = styled(Field)`
  max-width: 99%;
  max-height: 200px;
  margin: 15px 0;
  outline: none;
  border: 1px solid transparent;
  font: normal normal normal 12px/12px ${FONT.centuryGothic};
  :focus {
    border: 1px solid ${COLORS.richElectricBlue};
  }
`;

export const SelectContainer = styled.div<IInputProps>`
  width: ${(props) => (props.lessWidth ? '45%' : '99%')};

  .select {
    width: 100%;
    height: 31px;
    margin-top: 10px;
    background: ${COLORS.white};
    box-shadow: 0px 0px 4px #0000001d;
    border-radius: 5px;
    border: 1px solid transparent;
    color: ${(props) => (props.isNotPlaceholder ? 'black' : COLORS.grayForm)};
    outline: none;
    font: normal normal normal 12px/12px ${FONT.centuryGothic};

    :focus {
      border: 1px solid ${COLORS.richElectricBlue};
    }
  }

  @media (max-width: ${TABLET_800}px) {
    width: 100%;
  }
`;

export const InputContainer = styled.div`
  width: auto;
  height: auto;
  position: relative;
`;

export const ErrorCommentContainer = styled.div`
  position: absolute;
  top: 16px;
  right: 0px;
  font-size: 11px;

  @media (max-width: ${TABLET_800}px) {
    display: none;
  }
`;

export const ErrorCommentContent = styled.div`
  position: relative;
`;

export const ErrorCommentImage = styled.img`
  position: absolute;
  top: 0;
  width: 70px;
`;

export const ErrorCommentText = styled.div`
  position: absolute;
  top: 5px;
  left: 11px;
  z-index: 2;
  color: white;
  font: normal normal normal 11px Century Gothic;
`;

export const ErrorImageMobile = styled.img<IInputProps>`
  display: none;

  @media (max-width: ${TABLET_800}px) {
    display: block;
    position: absolute;
    width: 18px;
    top: 16px;
    right: ${(props) => (props.hasMoreRightSpace ? '24px' : '5px')};
    z-index: 2;
  }
`;

export const RequiredBottom = styled.img`
  position: absolute;
  width: 70px;
  //position: absolute;
  width: 70px;
  bottom: -34px;
  right: -20px;
  z-index: 2;

  @media (max-width: ${TABLET_800}px) {
    display: none;
  }
`;

export const CaptchaContainer = styled.div`
  @media (max-width: 400px) {
    .g-recaptcha {
      // eslint-disable-next-line
      -webkit-transform: scale(0.77);
      -webkit-transform-origin: 0 50%;
      transform: scale(0.77);
      transform-origin: 0 50%;
    }
    // eslint-disable-next-line
    #index .g-recaptcha {
      -webkit-transform-origin: 0 100%;
      transform-origin: 0 100%;
    }
  }
`;

export const CustomOption = styled.option`
  color: #161616;
`;

interface IInputProps {
  lessWidth?: boolean;
  alignLeft?: boolean;
  marginTop?: string;
  hasMoreRightSpace?: boolean;
  hasErrors?: boolean;
  isNotPlaceholder?: boolean;
}
