import styled from 'styled-components';

export const VideoSection = styled.div`
  //display: flex;
  width: 100%;
  max-width: 1366px;
  max-height: 840px;
  margin: 0 auto;
  padding: 0 30px;
`;

export const VideoContainer = styled.div`
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
  background-color: #f8f8f8;
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;
