import { CONFIG } from '@global/constants/urls';
import { get, post } from './API';

export const Contact = async (
  fields: any,
  recaptchaValue: string,
  onSuccess: any,
  onError: any,
) => {
  try {
    await post(
      `${CONFIG.STRAPI_API_URL}/api/contact`,
      {
        fields,
        recaptchaValue,
      },
      {
        'Content-Type': 'application/json',
      },
    );
    onSuccess();
  } catch (error: any) {
    onError(error);
  }
};

// eslint-disable-next-line consistent-return
export const getTrainingData = async () => {
  try {
    const response = await get(`${CONFIG.STRAPI_API_URL}/api/zoom`, {
      'Access-Control-Allow-Origin': '*',
    });
    return response.data;
  } catch (error: any) {
    console.log(error);
  }
};
