import React from 'react';
import arrowImage from '@assets/arrow.svg';
import { BACKGROUND } from '@global/styles/colors';
import { Tab, TabText, TabArrow, Arrow } from './Tabs2.styles';
import { Tab2Props } from './Tabs2.types';

const Tabs2 = ({ tab, activeCategory, setActiveCategory }: Tab2Props) => {
  const selectedColor =
    (tab.Vertical_Tab_v1_Section_Color === '#ffffff' || !tab.Vertical_Tab_v1_Section_Color) &&
    tab.Vertical_Tab_v1_Section_Gradient_Color
      ? BACKGROUND[tab.Vertical_Tab_v1_Section_Gradient_Color]
      : `background: ${tab.Vertical_Tab_v1_Section_Color}`;

  return (
    <Tab
      isActive={tab.Vertical_Tab_v1_Tab_Name === activeCategory}
      color={selectedColor}
      onClick={() => setActiveCategory(tab.Vertical_Tab_v1_Tab_Name)}
    >
      <TabText>{tab.Vertical_Tab_v1_Tab_Name}</TabText>
      <TabArrow>
        <Arrow isActive={tab.Vertical_Tab_v1_Tab_Name === activeCategory} src={arrowImage} />
      </TabArrow>
    </Tab>
  );
};

export default Tabs2;
