export const COLORS = {
  black: '#000000',
  cornflowerBlue: '#07A9CDEA',
  darkGray: '#414141',
  gray: '#383838',
  grayTopbarItem: '#707070',
  greenHaze: '#009144',
  blueGreen: '#0697C4',
  persianGreen: '#009F9D',
  plum: '#8F278C',
  resolutionBlue: '#161616',
  transparent: '#40404000',
  vermilion: '#ec4747',
  violetRed: '#EE2A7B',
  white: '#FFFFFF',
  Blue: '#0792B1',
  Green: '#009444',
  darkGreen: '#015F2C',
  navyBlue: '#030B65',
  Purple: '#92278F',
  darkPurple: '#760C73',
  grayForm: '#848484',
  Raspberry: '#DD045E',
  Pink: '#DD045E',
  Orange: '#E57D00',
  vampireBlack: '#060707',
  pacificBlue: '#07A9CE',
  beer: '#F79420',
  androidGreen: '#9BBA3B',
  electricPink: '#EB2B7B',
  cherry: '#EF233C',
  lightPurple: '#e2e4e8',
  spanishGreen: '#01924B',
  boxShadow: '#00000029',
  richElectricBlue: '#009ACE',
  indigoDye: '#031B91',
  nero: '#222222',
  orangeTab: '#F7941D',
  greenTab: '#009444',
  purpleTab: '#92278F',
  bondiBlue: '#0192B1',
  blueLagoon: '#047891',
};

export const BACKGROUND = {
  Solid_White: 'background: #FFFFFF;',
  Solid_Blue: 'background: #0697C4;',
  Solid_Gray:
    'background: linear-gradient(180deg, rgba(116, 26, 124, 0.04) 0%, rgba(6, 151, 196, 0.04) 100%); box-shadow: 0px 0px 24px #00000029;',
  Blue_to_Green_gradient:
    'background: linear-gradient(180deg, rgba(6, 151, 196, 1) 0%, rgba(0, 159, 157, 1) 55%, rgba(0, 145, 68, 1) 100%);',
  Green_to_Blue_gradient:
    'background: linear-gradient(180deg, rgba(0, 145, 68, 1) 0%, rgba(0, 159, 157, 1) 55%, rgba(6, 151, 196, 1) 100%);',
  Purple_to_Blue_gradient:
    'background: linear-gradient(180deg, rgba(143, 39, 140, 1) 0%, rgba(6, 151, 196, 1) 100%);',
  Blue_to_Purple_gradient:
    'background: linear-gradient(180deg, rgba(6, 151, 196, 1) 0%, rgba(143, 39, 140, 1) 100%);',
  Red_to_Orange_gradient:
    'background: linear-gradient(180deg, rgba(235, 42, 123, 1) 0%, rgba(239, 88, 81, 1) 54%, rgba(244, 145, 29, 1) 100%);',
  Light_blue_to_Blue_gradient:
    'background: linear-gradient(180deg, rgba(0,166,203,1) 0%, rgba(6, 151, 196, 1) 100%);',
  Blue_to_Light_blue_gradient:
    'background: linear-gradient(180deg, rgba(6, 151, 196, 1) 0%, rgba(0,166,203,1) 100%);',
  Blue_to_Magenta_gradient:
    'background: linear-gradient(180deg, rgba(6, 151, 196, 1) 0%, rgba(143,39,140,1) 100%);',
  Magenta_to_Blue_gradient:
    'background: linear-gradient(180deg, rgba(143,39,140,1) 0%, rgba(6, 151, 196, 1) 100%);',
  Purple_to_Blue_Transparent_gradient:
    'background: transparent linear-gradient(180deg, #741A7C14 0%, #00A9CE0F 100%) 0% 0% no-repeat padding-box;',
  Sea_Blue_gradient: 'background: linear-gradient(180deg, #54BFFF 0%, #0F4570 100%);',
  Blue_Junior_to_Green_gradient:
    'background: linear-gradient(180deg, rgb(6, 151, 196) 0%, #9BBA3C 100%);',
  Lime_to_Blue_gradient:
    'background: linear-gradient(180deg, rgba(155, 186, 60, 1) 0%, rgba(0, 169, 206, 1) 100%);',
  Blue_to_Lime_gradient:
    'background: linear-gradient(180deg, rgba(0, 169, 206, 1) 0%, rgba(155, 186, 60, 1) 100%);',
  Yellow_Green_to_Blue_gradient: `background-color: rgb(0, 166, 203);
    background-image: linear-gradient(0deg, #00A9CE00 0%, #9BBA3C 100%);
    background-repeat: no-repeat;
    background-position: 0% 0%;
    padding: 0;
    box-sizing: border-box;`,
  Pathful_Black: 'background: #161616;',
};

export const itsLigthColor = (background: keyof typeof BACKGROUND) => {
  if (
    background === 'Solid_Gray' ||
    background === 'Solid_White' ||
    background === 'Purple_to_Blue_Transparent_gradient'
  ) {
    return true;
  }
  return false;
};
