import styled from 'styled-components';

import { FONT } from '@global/styles/default';
import { COLORS } from '@global/styles/colors';
import { MOBILE_575, TABLET_768 } from '@global/styles/sizes';

export const SuccessTitle = styled.h1`
  color: blue;
  font: normal normal bold 26px/32px Century Gothic;
  color: ${COLORS.white};
  margin-top: 22px;

  @media (max-width: ${MOBILE_575}px) {
    font-size: 18px;
    line-height: 24px;
  }
`;

export const SuccesContainer = styled.div`
  background: transparent linear-gradient(273deg, #009144 0%, #00a6cb 100%) 0% 0% no-repeat
    padding-box;
  padding: 46px 0;
  max-width: 714px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  border-radius: 24px;

  @media (max-width: ${TABLET_768}px) {
    max-width: 500px;
  }
  @media (max-width: ${MOBILE_575}px) {
    max-width: 340px;
  }
`;

export const TopCircle = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  height: 220px;

  @media (max-width: ${TABLET_768}px) {
    height: 150px;
  }
`;

export const BottomCircle = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  height: 220px;

  @media (max-width: ${TABLET_768}px) {
    height: 150px;
  }
`;

export const Button = styled.button`
  border-radius: 5px;
  border: none;
  color: ${COLORS.richElectricBlue};
  padding: 10px 20px;
  margin-top: 43px;
  max-width: 216px;
  width: 100%;
  font: normal normal bold 18px ${FONT.centuryGothic};
  background-color: ${COLORS.white};
  :hover {
    background-image: linear-gradient(90deg, ${COLORS.white} 0%, ${COLORS.cornflowerBlue} 100%);
  }

  @media (max-width: ${MOBILE_575}px) {
    margin-top: 23px;
    max-width: 150px;
    font-size: 16px;
  }
`;
