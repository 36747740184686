import React from 'react';

import { GlobalWrapper } from '@global/styles/grid';
import { COLORS } from '@global/styles/colors';

import {
  Arrow,
  ChildrenMobile,
  FilterBar,
  FilterIndicator,
  FilterItem,
  FilterList,
  FilterSection,
  ItemText,
} from './tabs.styles';

import pinkArrow from './assets/pinkArrow.svg';
import richElectricBlueArrow from './assets/richElectricBlueArrow.svg';

import { TabsProps } from './tabs.types';

const Tabs = ({
  tabsNames,
  tabsColor,
  areVerticalTabs,
  activeItem,
  setActiveItem,
  children,
  color,
}: TabsProps) => {
  const [category, setCategory] = React.useState<string>('0%');
  const [prevCategory, setPrevCategory] = React.useState<string>('10%');

  const totalItems = tabsNames.length;

  const handleClick = (clickCategory: string, itemNumber: number) => {
    let categoryValue = clickCategory;
    setPrevCategory(category);
    setCategory(`${itemNumber * (100 / totalItems)}%`);
    if (window.innerWidth <= 801) {
      categoryValue = clickCategory === activeItem ? '' : clickCategory;
    }
    setActiveItem(categoryValue);
  };

  return (
    <GlobalWrapper>
      <FilterSection areVerticalTabs={areVerticalTabs}>
        <FilterList areVerticalTabs={areVerticalTabs}>
          {tabsNames.map((tabName, index) => {
            return (
              <>
                <FilterItem
                  areVerticalTabs={areVerticalTabs}
                  key={`item${tabName}`}
                  isActive={activeItem === tabName}
                  onClick={() => handleClick(tabName, index)}
                  totalItems={totalItems}
                  color={COLORS[color] || color}
                  backgroundColor={
                    tabsColor && (COLORS[`${tabsColor[index]}Tab`] || tabsColor[index])
                  }
                >
                  {areVerticalTabs ? <ItemText>{tabName}</ItemText> : tabName.toString()}
                  <Arrow
                    src={color === 'Raspberry' ? pinkArrow : richElectricBlueArrow}
                    isActive={activeItem === tabName}
                    color={COLORS[color] || color}
                    isWhite={areVerticalTabs}
                  />
                </FilterItem>
                {activeItem === tabName && <ChildrenMobile>{children}</ChildrenMobile>}
              </>
            );
          })}
        </FilterList>
        <FilterBar>
          <FilterIndicator
            from={prevCategory}
            to={category}
            totalItems={totalItems}
            color={COLORS[color] || color}
          />
        </FilterBar>
      </FilterSection>
    </GlobalWrapper>
  );
};

export default Tabs;
