import styled from 'styled-components';

import { COLORS } from '@global/styles/colors';

import { ZINDEX } from '@global/styles/sizes';

export const OpacityLayer = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${ZINDEX.modal};
`;

export const Container = styled.div`
  //background-color: ${COLORS.white};
  min-width: 100px;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  //overflow-y: scroll;
`;
