import styled, { css, keyframes } from 'styled-components';

import { COLORS } from '@global/styles/colors';
import { FONT_FAMILY } from '@global/styles/fonts';
import { TABLET_1023, TABLET_800 } from '@global/styles/sizes';

export const FilterSection = styled.div<IDiv>`
  width: 100%;
  height: fit-content;
  margin: 0 auto 60px;
  ${(props) => props.areVerticalTabs && `margin-bottom: 0;`}
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  border-radius: 14px;

  ${({ areVerticalTabs }) =>
    areVerticalTabs
      ? css`
          height: 100%;
        `
      : css`
          background: #f7f9fc;
          box-shadow: 0px 3px 6px ${COLORS.boxShadow};
        `}

  @media (max-width: ${TABLET_1023}px) {
    margin: 0 auto 48px;
  }
  @media (max-width: ${TABLET_800}px) {
    margin: 0;
    display: block;
    background: none;
    box-shadow: none;
    border-radius: none;
  }
`;

export const ItemText = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font: normal normal bold 20px/28px Ulm Grotesk;
`;

export const FilterList = styled.ul<IDiv>`
  width: 90%;
  min-height: 80px;
  padding: 0;
  display: flex;
  justify-content: center;

  ${({ areVerticalTabs }) =>
    areVerticalTabs
      ? css`
          flex-direction: column;
          height: 80%;
        `
      : css``}

  @media (max-width: ${TABLET_800}px) {
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: ${COLORS.transparent};
    box-shadow: none;
  }
`;

export const FilterItem = styled.li<IFilterItem>`
  ${(props) => props.totalItems && `width: calc(100%/${props.totalItems});`}
  ${(props) => props.areVerticalTabs && `width: 100%;`}
  ${(props) => props.areVerticalTabs && `max-height: 200px;`}
  height: 100%;
  margin: 10px 12px;
  padding: 0;
  font: 800 20px/28px 'Century Gothic';
  align-self: center;
  ${FONT_FAMILY.CENTURY_GOTHIC};
  color: ${COLORS.gray};
  word-wrap: normal;
  text-align: center;
  cursor: pointer;
  box-sizing: border-box;
  transition: color 0.3s ease-in-out;
  ${(props) => props.isActive && `color: ${props.color};`};

  ${({ areVerticalTabs }) =>
    areVerticalTabs
      ? css`
          padding: 20px 0;
          ${(props) => `background-color: ${props?.isActive ? props?.backgroundColor : 'white'};`};
          ${(props) => props.isActive && `color: white;`}

          border-radius: 14px;
        `
      : css``}

  &:hover {
    ${(props) => !props.isActive && `color: ${COLORS.Blue};`};
  }

  @media (max-width: ${TABLET_1023}px) {
    font: 800 18px/28px 'Century Gothic';
  }

  @media (max-width: ${TABLET_800}px) {
    width: 100%;
    min-height: 60px;
    margin: 3px 0;
    padding: 16px 50px;
    font: 800 20px/28px 'Century Gothic';
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    justify-content: center;
    background: #f7f9fc;
    box-shadow: 0px 3px 6px ${COLORS.boxShadow};
    box-sizing: border-box;
    position: relative;
    word-break: break-word;
    border-radius: 8px;
    align-items: flex-start;

    ${({ areVerticalTabs }) =>
      areVerticalTabs &&
      css`
        min-height: 80px;
        margin-top: 14px;
        ${(props) => `background-color: ${props.backgroundColor};`}
        color: white;
      `}
  }
`;

export const Arrow = styled.img<IArrowProps>`
  width: 27px;
  display: inline-block;
  transition: 0.3s ease;
  position: absolute;
  right: 20px;
  top: 40%;
  ${(props) =>
    props.isActive
      ? 'transform: rotate(0deg);'
      : 'transform: rotate(180deg); filter: grayscale(100%) brightness(1);'};
  ${({ isWhite }) =>
    isWhite &&
    css`
      filter: grayscale(100%) brightness(10);
    `}
  @media (min-width: 801px) {
    display: none;
  }
`;

export const FilterBar = styled.div`
  width: 90%;
  height: 3px;
  position: relative;
  @media (max-width: ${TABLET_800}px) {
    display: none;
  }
`;

const move = (from: string, to: string) => keyframes`
  from { left: ${from} };
  to { left: ${to} };
`;

export const FilterIndicator = styled.div<IFilterIndicator>`
  ${(props) => props.totalItems && `width: calc(100% / ${props.totalItems});`}
  height: 5px;
  ${(props) => props.color && `background: ${props.color};`}
  position: absolute;
  top: -2px;
  animation: ${(props) => move(props.from, props.to)} 0.6s forwards;
  transition: transform 0.6s ease;
`;

export const ChildrenMobile = styled.div`
  display: none;

  @media (max-width: ${TABLET_800}px) {
    display: block;
  }
`;

export const VerticalFilterSection = styled.div`
  height: 100%;
`;

Arrow.defaultProps = {
  isActive: false,
};

interface IDiv {
  areVerticalTabs?: boolean;
}
interface IFilterIndicator {
  from: string;
  to: string;
  totalItems: number;
  color: string;
}

interface IFilterItem {
  isActive?: boolean;
  activeCategory?: string;
  totalItems: number;
  color: string;
  areVerticalTabs?: boolean;
  backgroundColor?: string;
}

interface IArrowProps {
  isActive: boolean;
  color: string;
  isWhite?: boolean;
}
