import styled, { css } from 'styled-components';

import { COLORS } from '@global/styles/colors';
import { TABLET_992 } from '@global/styles/sizes';

export const Background = styled.div`
  background-color: white;
  border-radius: 7px;
  height: fit-content;
`;

export const ButtonContainer = styled.button<IButtonProps>`
  width: ${({ width }) => width || 'auto'};
  min-height: 46px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: ${({ width }) => (width ? '7px 0px 9px' : '7px 20px 9px')};
  font: normal 20px/28px 'Century Gothic';
  color: ${COLORS.white};
  box-shadow: 0px 3px 6px ${COLORS.boxShadow};
  border-radius: 7px;
  transition: all 0.2s ease;
  div {
    div {
      p {
        color: ${({ whiteBackground, color }) =>
          whiteBackground ? `${color} !important` : COLORS.white};
      }
    }
  }

  ${(props) =>
    // eslint-disable-next-line
    !props.disabled
      ? props.isActive
        ? css`
            background-color: ${props.activeColor};
            border: 1px solid ${props.activeColor};
          `
        : css`
            background-color: ${props.color};
            border: 1px solid ${props.color};
            &:hover,
            &:active {
              div {
                div {
                  p {
                    color: ${COLORS.white};
                  }
                }
              }
              background: ${COLORS.blueLagoon};
              border: 1px solid ${COLORS.blueLagoon};
            }
          `
      : css`
          background-color: ${props.color};
          border: 1px solid ${props.color};
        `};

  @media (max-width: ${TABLET_992}px) {
    box-sizing: border-box;
    position: relative;
    word-break: break-word;
  }
`;

export const LinkContainer = styled.a<IButtonProps>`
  width: ${({ width }) => width || 'fit-content'};
  word-break: break-word;
  text-decoration: none !important;
  box-sizing: border-box;
  min-height: 46px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: ${({ width }) => (width ? '7px 0px 9px' : '7px 20px 9px')};
  font: normal 20px/28px 'Century Gothic';
  div {
    div {
      p {
        color: ${({ whiteBackground, color }) =>
          whiteBackground ? `${color} !important` : COLORS.white};
      }
    }
  }
  box-shadow: 0px 3px 6px ${COLORS.boxShadow};
  border-radius: 7px;
  transition: all 0.2s ease;

  ${(props) =>
    !props.disabled && props.isActive
      ? css`
          background-color: ${props.activeColor};
          border: 1px solid ${props.activeColor};
        `
      : css`
          background-color: ${props.whiteBackground ? 'white' : props.color};
          border: 1px solid ${props.color};
          &:hover,
          &:active {
            background: ${COLORS.blueLagoon};
            border: 1px solid ${COLORS.blueLagoon};
            color: ${COLORS.white};
          }
        `};

  @media (max-width: ${TABLET_992}px) {
    box-sizing: border-box;
    position: relative;
    word-break: break-word;
  }
`;

export const Arrow = styled.img<IArrowProps>`
  width: 27px;
  cursor: pointer;
  transition: 0.5s ease;
  ${(props) => (props.isActive ? 'transform: rotate(0deg);' : 'transform: rotate(180deg);')}
  display: ${(props) => (props.dropdownButton ? ' inline-block' : 'none')};
  transition: 0.5s ease;
  position: absolute;
  right: 25px;
  top: 40%;
`;

ButtonContainer.defaultProps = {
  isActive: false,
  color: `${COLORS.pacificBlue}`,
  activeColor: `${COLORS.Blue}`,
};

Arrow.defaultProps = {
  isActive: false,
  dropdownButton: false,
};

interface IButtonProps {
  isActive: boolean;
  color: string;
  activeColor: string;
  width?: string;
  disabled?: boolean;
  whiteBackground?: boolean;
}

interface IArrowProps {
  isActive: boolean;
  dropdownButton: boolean;
}
