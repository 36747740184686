import { COLORS } from '@global/styles/colors';
import { TABLET_800, TABLET_992 } from '@global/styles/sizes';
import styled, { css } from 'styled-components';

export const Tab = styled.div<{ color: string; isActive: boolean }>`
  width: initial;
  padding: 0 22px;
  ${({ isActive, color }) => (isActive ? color : `background: ${COLORS.white}`)};
  border-radius: 14px;
  height: 87px;
  display: flex;
  align-items: center;
  border: 1px solid #161616;
  cursor: pointer;
  div {
    ${({ isActive }) => isActive && 'color: white !important;'}
  }
  &:hover {
    ${({ isActive }) =>
      !isActive &&
      css`
        color: white !important;
        background: #0b92b2;
        border: 1px solid ${COLORS.Blue};
      `}
    div {
      color: white !important;
    }
  }
  @media (max-width: ${TABLET_992}px) {
    padding: 0 20px;
  }
  @media (max-width: ${TABLET_800}px) {
    background: ${({ color }) => color};
    position: relative;
    padding: 0px 50px 0 20px;
  }
`;

export const TabText = styled.div`
  color: #161616;
  font: normal normal bold 20px/26px Ulm Grotesk;

  @media (max-width: ${TABLET_800}px) {
    font: normal normal bold 20px/26px Ulm Grotesk;
  }
`;

export const TabArrow = styled.div`
  display: none;
  @media (max-width: ${TABLET_800}px) {
    display: flex;
  }
`;

export const Arrow = styled.img<{ isActive: boolean }>`
  width: 27px;
  display: inline-block;
  transition: 0.3s ease;
  position: absolute;
  right: 20px;
  top: 40%;
  ${(props) =>
    props.isActive
      ? 'transform: rotate(0deg);'
      : 'transform: rotate(180deg); filter: grayscale(100%) brightness(1);'};
  filter: grayscale(100%) brightness(10);
  @media (min-width: 801px) {
    display: none;
  }
`;
