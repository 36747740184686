import styled from 'styled-components';

import {
  DESKTOP_1920,
  DESKTOP_1280,
  TABLET_992,
  TABLET_768,
  MOBILE_575,
  MOBILE_380,
} from './sizes';

export const GlobalWrapper = styled.div`
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;

  @media (max-width: ${DESKTOP_1920}px) {
    max-width: 1086px;
  }
  @media (max-width: ${DESKTOP_1280}px) {
    max-width: 960px;
  }
  @media (max-width: ${TABLET_992}px) {
    max-width: 770px;
  }
  @media (max-width: ${TABLET_768}px) {
    max-width: 500px;
  }
  @media (max-width: ${MOBILE_575}px) {
    max-width: 360px;
  }
  @media (max-width: ${MOBILE_380}px) {
    max-width: 300px;
  }
`;
