import React, { useEffect, useRef, useState } from 'react';
import bodymovin, { AnimationItem } from 'lottie-web';

import { CSSProperties } from 'styled-components';
import { LottieContainer } from './styles';

export const LottiePlayer: React.FC<IProps> = (props: IProps) => {
  const {
    autoplay,
    loop,
    animationData,
    animationHeight,
    animationHeightMobile,
    animationWidth,
    play,
    name,
    assetsPath,
    onComplete,
    containerStyle,
  } = props;
  const containerRef = useRef(null);
  const [animation, setAnimation] = useState<AnimationItem | null>(null);
  const [fetchedAnimationData, setFetchedAnimationData] = useState<any>(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onCompleteAnim = () => {
    animation?.goToAndStop(0);
    if (onComplete) {
      onComplete();
    }
  };

  useEffect(() => {
    const fetchRemoteJSON = async () => {
      fetch(animationData)
        .then((res) => res.json())
        .then((out) => setFetchedAnimationData(out))
        .catch((err) => {
          throw err;
        });
    };

    if (
      typeof animationData !== 'object' &&
      animationData.match(
        // eslint-disable-next-line
        /(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&//=]*)(.json)/g,
      )
    ) {
      fetchRemoteJSON();
    } else {
      setFetchedAnimationData(animationData);
    }
  }, [animationData]);

  useEffect(() => {
    // define lottie animation
    // eslint-disable-next-line @typescript-eslint/no-unused-vars

    if (fetchedAnimationData) {
      if (containerRef && containerRef.current) {
        try {
          const anim = bodymovin.loadAnimation({
            container: containerRef.current,
            renderer: 'svg',
            autoplay: autoplay || false,
            loop: loop || false,
            animationData: fetchedAnimationData,
            assetsPath,
          });
          setAnimation(anim);
        } catch (error) {
          console.error(error);
        }
      }
    }
    return () => animation?.destroy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedAnimationData, autoplay, loop, assetsPath]);

  useEffect(() => {
    if (animation) {
      animation.addEventListener('complete', onCompleteAnim);
      animation.removeEventListener('complete', onCompleteAnim);
    }
  }, [animation, onCompleteAnim]);

  useEffect(() => {
    if (animation) {
      if (play) {
        animation.play();
      } else {
        animation.stop();
      }
    }
  }, [play, animation]);

  return (
    <LottieContainer
      style={containerStyle}
      height={animationHeight || ''}
      heightMobile={animationHeightMobile || ''}
      width={animationWidth || ''}
      className="lottie--container"
      ref={containerRef}
      key={name || undefined}
    />
  );
};

interface IProps {
  animationData: any;
  loop?: boolean | number;
  autoplay?: boolean;
  animationWidth?: string;
  animationHeight?: string;
  animationHeightMobile?: string;
  play?: boolean;
  name?: string;
  onComplete?: () => void;
  assetsPath?: string;
  containerStyle?: CSSProperties;
}

const defaultProps = {
  loop: false,
  autoplay: true,
  animationWidth: 'auto',
  animationHeight: 'auto',
  animationHeightMobile: 'auto',
  play: true,
  name: '',
  assetsPath: '',
  onComplete: () => {},
  containerStyle: {},
};
LottiePlayer.defaultProps = defaultProps;
