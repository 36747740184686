import React, { useEffect, useState } from 'react';

import { CSSProperties } from 'styled-components';
import { LottiePlayer } from './LottiePlayer';

export const MultipleLottiePlayer: React.FC<IProps> = (props: IProps) => {
  const { animationArrayData, containerStyle, play } = props;
  const [fetchedAnimationArrayData, setFetchedAnimationArrayData] = useState<any>(null);

  const getLoop = (loop: any) => {
    if (loop === 'infinite') {
      return true;
    }
    return loop;
  };

  useEffect(() => {
    const fetchRemoteJSON = async () => {
      fetch(animationArrayData)
        .then((res) => res.json())
        .then((out) => {
          if (!out?.length) {
            const animationsArray = [out];
            setFetchedAnimationArrayData(animationsArray);
          } else {
            setFetchedAnimationArrayData(out);
          }
        })
        .catch((err) => {
          throw err;
        });
    };

    if (
      typeof animationArrayData === 'string' &&
      animationArrayData.match(
        // eslint-disable-next-line
        /(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&//=]*)(.json)/g,
      )
    ) {
      fetchRemoteJSON();
    } else {
      setFetchedAnimationArrayData(animationArrayData);
    }
  }, [animationArrayData]);

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
        ...containerStyle,
      }}
      className="lottie--container"
    >
      {fetchedAnimationArrayData &&
        fetchedAnimationArrayData?.length > 0 &&
        fetchedAnimationArrayData.map((animation: any, index: number) => {
          return (
            <LottiePlayer
              key={index}
              containerStyle={{ position: 'absolute' }}
              animationData={animation?.animationData ? animation?.animationData : animation}
              loop={getLoop(animation?.loop)}
              play={play}
              animationHeight="100%"
              animationWidth="100%"
            />
          );
        })}
    </div>
  );
};

interface IProps {
  containerStyle?: CSSProperties;
  animationArrayData: any;
  play?: boolean;
}
const defaultProps = {
  containerStyle: {},
  play: true,
};
MultipleLottiePlayer.defaultProps = defaultProps;
