import React from 'react';

import { ACTIVE_CAMPAIGN } from '@global/constants/urls';
import SuccesModal from '@components/Modals/Success/SuccessModal';

import FormModal from '@components/Modals/Form';

import VideoModal from '@components/Modals/Video';
import { Contact } from 'services/integrations';

const ModalSwitch = ({ modalKey, setActiveModal, options }: IProps) => {
  switch (modalKey) {
    case 'success':
      return <SuccesModal onClose={() => setActiveModal(null)} />;
    case 'request-a-demo':
      return (
        <FormModal
          onClose={() => setActiveModal(null)}
          onSubmit={Contact}
          options={{
            campaignName: 'request-a-demo',
            title: 'REQUEST A DEMO',
            subtitle: (
              <div>
                Already a customer?{' '}
                <a target="_blank" href={ACTIVE_CAMPAIGN.CONTACT_US} rel="noreferrer">
                  {' '}
                  Please click here to schedule training.
                </a>
              </div>
            ),
            ...options,
          }}
          onSuccess={() => setActiveModal('success')}
        />
      );
    case 'request-a-quote':
      return (
        <FormModal
          onSubmit={Contact}
          onClose={() => setActiveModal(null)}
          options={{
            subtitle: (
              <div>
                Already a customer?{' '}
                <a href={ACTIVE_CAMPAIGN.CONTACT_US}> Please click here to schedule training.</a>
              </div>
            ),
            campaignName: 'request-a-quote',
            title: 'REQUEST A QUOTE',
            ...options,
          }}
          onSuccess={() => setActiveModal('success')}
        />
      );
    case 'video':
      return <VideoModal onClose={() => setActiveModal(null)} options={options} />;
    default:
      return null;
  }
};

interface IProps {
  modalKey: string;
  setActiveModal: (modal: string | null) => void;
  options: any;
}

export default ModalSwitch;
