import React, { useState, createRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import ReactTooltip from 'react-tooltip';

import { Container as ModalContainer, OpacityLayer } from '@components/Modals/modalStyles';
import Button from '@components/Button';

import { Formik, Field } from 'formik';
import * as Yup from 'yup';

import {
  Container,
  Text,
  Header,
  FormikForm,
  InputContainer,
  Input,
  RowInputs,
  FormContainer,
  LineTop,
  LineBottom,
  ErrorMessage,
  TextArea,
  Cross,
  SelectContainer,
  ErrorCommentContainer,
  ErrorCommentImage,
  ErrorCommentText,
  ErrorCommentContent,
  ErrorImageMobile,
  RequiredBottom,
  ButtonContainer,
} from './Form.styles';

import RightLine from './assets/rightLine.svg';
import LeftLine from './assets/leftLine.svg';
import CrossIcon from './assets/arrow.svg';

import LeftComment from './assets/LeftComment.svg';

import InfoError from './assets/InfoIcon.svg';

import RequiredBottomBox from './assets/RequiredBottomBox.svg';

// eslint-disable-next-line
const Form = (props: IProps) => {
  const { onClose, options, onSuccess, onSubmit } = props;
  const [captchaValidate, setCaptchaValidate] = useState(false);
  const [errorCaptcha, setErrorCaptcha] = useState(false);

  const recaptchaRef = createRef<any>();

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const fieldsNames: any = {
    firstName: 'FIRSTNAME',
    lastName: 'LASTNAME',
    organizationName: 'ORGANIZATION_NAME',
    email: 'EMAIL',
    state: 'STATE',
    phone: 'PHONE',
    title: 'TITLE',
    organizationType: 'ORGANIZATION_TYPE',
    jobTitle: 'WHICH_ROLE_BEST_DESCRIBES_YOU',
    comments: 'MESSAGE',
    campaignFrom: 'CAMPAIGN_FROM',
    source: 'SOURCE',
  };

  const SignupSchema = Yup.object().shape({
    firstName: Yup.string().min(2, 'Invalid').max(50, 'Invalid').required('Required'),
    lastName: Yup.string().min(2, 'Invalid').max(50, 'Invalid').required('Required'),
    organizationName: Yup.string().min(2, 'Invalid').max(50, 'Invalid').required('Required'),
    email: Yup.string().email('Invalid').required('Required'),
    state: Yup.string().required('Required'),
    phone: Yup.string().matches(phoneRegExp, 'Invalid').required('Required'),
    title: Yup.string().required('Required'),
    organizationType: Yup.string().required('Required'),
    jobTitle: Yup.string().min(2, 'Invalid').required('Required'),
  });

  const onChange = () => {
    setCaptchaValidate(true);
  };

  const getFields = (values: any) => {
    const fields = Object.keys(values).map(function (key: any) {
      const fieldName = fieldsNames[key];
      const value = values[key];
      return {
        field: fieldName,
        value,
      };
    });

    const contact = {
      ...values,
      fieldValues: fields,
    };
    return contact;
  };

  const handleSubmit = async (values: any) => {
    if (!captchaValidate) {
      setErrorCaptcha(true);
    } else {
      const recaptchaValue = recaptchaRef.current.getValue();
      const fields = getFields(values);
      await onSubmit(fields, recaptchaValue, onSuccess, () => console.error('error'));
      // onSuccess();
    }
  };

  return (
    <OpacityLayer>
      <ModalContainer>
        <Container>
          <LineTop src={LeftLine} />
          <Header>
            {options.title || `SCHEDULE MY DEMO`}
            <Cross src={CrossIcon} onClick={onClose} />
          </Header>
          <FormContainer>
            <Text> {options.subtitle || `SCHEDULE MY DEMO`}</Text>
            <Formik
              initialValues={{
                firstName: '',
                lastName: '',
                organizationName: '',
                email: '',
                state: '',
                phone: '',
                title: '',
                organizationType: '',
                jobTitle: '',
                comments: '',
                campaignFrom: options.campaignFrom || 'Request a Demo',
                source: window.location.href || 'generic',
              }}
              validateOnChange={false}
              validateOnBlur
              validationSchema={SignupSchema}
              onSubmit={(values) => handleSubmit(values)}
              validate={() => ({})}
            >
              {({ errors, touched }) => (
                <FormikForm>
                  <InputContainer>
                    <Input id="firstName" name="firstName" placeholder="Jane" />
                    {errors.firstName && touched.firstName ? (
                      <>
                        <ErrorCommentContainer>
                          <ErrorCommentContent>
                            <ErrorCommentImage src={LeftComment} />
                            <ErrorCommentText>{errors.firstName}</ErrorCommentText>
                          </ErrorCommentContent>
                        </ErrorCommentContainer>

                        <ErrorImageMobile data-tip data-for="error" src={InfoError} />
                        <ReactTooltip type="success" id="error" place="bottom" effect="solid">
                          {errors.firstName}
                        </ReactTooltip>
                      </>
                    ) : null}
                  </InputContainer>
                  <InputContainer>
                    <Input id="lastName" name="lastName" placeholder="Doe" />
                    {errors.lastName && touched.lastName ? (
                      <>
                        <ErrorCommentContainer>
                          <ErrorCommentContent>
                            <ErrorCommentImage src={LeftComment} />
                            <ErrorCommentText>{errors.lastName}</ErrorCommentText>
                          </ErrorCommentContent>
                        </ErrorCommentContainer>
                        <ErrorImageMobile data-tip data-for="error" src={InfoError} />
                        <ReactTooltip type="success" id="error" place="bottom" effect="solid">
                          {errors.lastName}
                        </ReactTooltip>
                      </>
                    ) : null}
                  </InputContainer>
                  <InputContainer>
                    <Input
                      id="organizationName"
                      name="organizationName"
                      placeholder="Organization Name"
                    />
                    {errors.organizationName && touched.organizationName ? (
                      <>
                        <ErrorCommentContainer>
                          <ErrorCommentContent>
                            <ErrorCommentImage src={LeftComment} />
                            <ErrorCommentText>{errors.organizationName}</ErrorCommentText>
                          </ErrorCommentContent>
                        </ErrorCommentContainer>
                        <ErrorImageMobile data-tip data-for="error" src={InfoError} />
                        <ReactTooltip type="success" id="error" place="bottom" effect="solid">
                          {errors.organizationName}
                        </ReactTooltip>
                      </>
                    ) : null}
                  </InputContainer>
                  <InputContainer>
                    <Input id="email" name="email" placeholder="jane@acme.com" type="email" />
                    {errors.email && touched.email ? (
                      <>
                        <ErrorCommentContainer>
                          <ErrorCommentContent>
                            <ErrorCommentImage src={LeftComment} />
                            <ErrorCommentText>{errors.email}</ErrorCommentText>
                          </ErrorCommentContent>
                        </ErrorCommentContainer>
                        <ErrorImageMobile data-tip data-for="error" src={InfoError} />
                        <ReactTooltip type="success" id="error" place="bottom" effect="solid">
                          {errors.email}
                        </ReactTooltip>
                      </>
                    ) : null}
                  </InputContainer>
                  <RowInputs>
                    <SelectContainer
                      lessWidth
                      isNotPlaceholder={
                        touched.state && errors.state !== '' && errors.state !== 'Required'
                      }
                    >
                      <InputContainer>
                        <Field className="select" name="state" as="select">
                          <option disabled selected value="">
                            State
                          </option>
                          <option value="AK">AK</option>
                          <option value="AL">AL</option>
                          <option value="AR">AR</option>
                          <option value="AZ">AZ</option>
                          <option value="CA">CA</option>
                          <option value="CO">CO</option>
                          <option value="CT">CT</option>
                          <option value="DC">DC</option>
                          <option value="DE">DE</option>
                          <option value="FL">FL</option>
                          <option value="GA">GA</option>
                          <option value="HI">HI</option>
                          <option value="IA">IA</option>
                          <option value="ID">ID</option>
                          <option value="IL">IL</option>
                          <option value="IN">IN</option>
                          <option value="KS">KS</option>
                          <option value="KY">KY</option>
                          <option value="LA">LA</option>
                          <option value="MA">MA</option>
                          <option value="MD">MD</option>
                          <option value="ME">ME</option>
                          <option value="MI">MI</option>
                          <option value="MN">MN</option>
                          <option value="MO">MO</option>
                          <option value="MS">MS</option>
                          <option value="MT">MT</option>
                          <option value="NC">NC</option>
                          <option value="ND">ND</option>
                          <option value="NE">NE</option>
                          <option value="NH">NH</option>
                          <option value="NJ">NJ</option>
                          <option value="NM">NM</option>
                          <option value="NV">NV</option>
                          <option value="NY">NY</option>
                          <option value="OH">OH</option>
                          <option value="OK">OK</option>
                          <option value="OR">OR</option>
                          <option value="PA">PA</option>
                          <option value="RI">RI</option>
                          <option value="SC">SC</option>
                          <option value="SD">SD</option>
                          <option value="TN">TN</option>
                          <option value="TX">TX</option>
                          <option value="UT">UT</option>
                          <option value="VA">VA</option>
                          <option value="VT">VT</option>
                          <option value="WA">WA</option>
                          <option value="WI">WI</option>
                          <option value="WV">WV</option>
                          <option value="WY">WY</option>
                          <option value="Other">Other</option>
                        </Field>
                        {errors.state && touched.state ? (
                          <>
                            <RequiredBottom src={RequiredBottomBox} />
                            <ErrorImageMobile
                              hasMoreRightSpace
                              data-tip
                              data-for="error"
                              src={InfoError}
                            />
                            <ReactTooltip type="success" id="error" place="bottom" effect="solid">
                              {errors.state}
                            </ReactTooltip>
                          </>
                        ) : null}
                      </InputContainer>
                    </SelectContainer>
                    <SelectContainer
                      lessWidth
                      isNotPlaceholder={
                        touched.phone && errors.phone !== '' && errors.phone !== 'Required'
                      }
                    >
                      <InputContainer>
                        <Input id="phone" name="phone" placeholder="Phone" type="phone" />
                        {errors.phone && touched.phone ? (
                          <>
                            <ErrorCommentContainer>
                              <ErrorCommentContent>
                                <ErrorCommentImage src={LeftComment} />
                                <ErrorCommentText>{errors.phone}</ErrorCommentText>
                              </ErrorCommentContent>
                            </ErrorCommentContainer>
                            <ErrorImageMobile data-tip data-for="error" src={InfoError} />
                            <ReactTooltip type="success" id="error" place="bottom" effect="solid">
                              {errors.phone}
                            </ReactTooltip>
                          </>
                        ) : null}
                      </InputContainer>
                    </SelectContainer>
                  </RowInputs>
                  <InputContainer>
                    <Input id="title" name="title" placeholder="Title" />
                    {errors.title && touched.title ? (
                      <>
                        <ErrorCommentContainer>
                          <ErrorCommentContent>
                            <ErrorCommentImage src={LeftComment} />
                            <ErrorCommentText>{errors.title}</ErrorCommentText>
                          </ErrorCommentContent>
                        </ErrorCommentContainer>
                        <ErrorImageMobile data-tip data-for="error" src={InfoError} />
                        <ReactTooltip type="success" id="error" place="bottom" effect="solid">
                          {errors.title}
                        </ReactTooltip>
                      </>
                    ) : null}
                  </InputContainer>
                  <InputContainer>
                    <SelectContainer
                      isNotPlaceholder={
                        touched.organizationType &&
                        errors.organizationType !== '' &&
                        errors.organizationType !== 'Required'
                      }
                    >
                      <Field
                        className="select"
                        name="organizationType"
                        id="organizationType"
                        as="select"
                      >
                        <option disabled selected value="">
                          Organization Type
                        </option>
                        <option value="School">School</option>
                        <option value="State Agency">State Agency</option>
                        <option value=">Workforce Organization">Workforce Organization</option>
                        <option value="Vocational Rehabilitation">Vocational Rehabilitation</option>
                        <option value="Post-Secondary Institution">
                          Post-Secondary Institution
                        </option>
                        <option value="TRIO Program">TRIO Program</option>
                        <option value="Other">Other</option>
                      </Field>
                    </SelectContainer>
                    {errors.organizationType && touched.organizationType ? (
                      <>
                        <ErrorCommentContainer>
                          <ErrorCommentContent>
                            <ErrorCommentImage src={LeftComment} />
                            <ErrorCommentText>{errors.organizationType}</ErrorCommentText>
                          </ErrorCommentContent>
                        </ErrorCommentContainer>
                        <ErrorImageMobile
                          hasMoreRightSpace
                          data-tip
                          data-for="error"
                          src={InfoError}
                        />
                        <ReactTooltip type="success" id="error" place="bottom" effect="solid">
                          {errors.organizationType}
                        </ReactTooltip>
                      </>
                    ) : null}
                  </InputContainer>
                  <InputContainer>
                    <SelectContainer
                      isNotPlaceholder={
                        touched.jobTitle && errors.jobTitle !== '' && errors.jobTitle !== 'Required'
                      }
                    >
                      <Field className="select" id="jobTitle" name="jobTitle" as="select">
                        <option disabled selected value="">
                          Role
                        </option>
                        <option value="Teacher">Teacher</option>
                        <option value="Counselor / Advisor">Counselor / Advisor</option>
                        <option value="Director">Director</option>
                        <option value="Coordinator / Specialist">Coordinator / Specialist</option>
                        <option value="Administrator">Administrator</option>
                        <option value="Procurement">Procurement</option>
                        <option value="Student or Parent">Student or Parent</option>
                        <option value="Other">Other</option>
                      </Field>
                    </SelectContainer>
                    {errors.jobTitle && touched.jobTitle ? (
                      <>
                        <ErrorCommentContainer>
                          <ErrorCommentContent>
                            <ErrorCommentImage src={LeftComment} />
                            <ErrorCommentText>{errors.jobTitle}</ErrorCommentText>
                          </ErrorCommentContent>
                        </ErrorCommentContainer>
                        <ErrorImageMobile
                          hasMoreRightSpace
                          data-tip
                          data-for="error"
                          src={InfoError}
                        />
                        <ReactTooltip type="success" id="error" place="bottom" effect="solid">
                          {errors.jobTitle}
                        </ReactTooltip>
                      </>
                    ) : null}
                  </InputContainer>
                  <Text alignLeft marginTop="37px">
                    Additional Comments
                  </Text>
                  <TextArea
                    id="comments"
                    name="comments"
                    placeholder="Comments"
                    component="textarea"
                    rows="4"
                    cols="50"
                    as="textarea"
                  />
                  <div>
                    <ReCAPTCHA
                      sitekey={
                        process.env.CAPTCHA_SITE_KEY || '6LfxMfMgAAAAAOYx7kzEswQFg_ERq5c3GJwZq7nJ'
                      }
                      ref={recaptchaRef}
                      onChange={() => onChange()}
                    />
                    {errorCaptcha && <ErrorMessage>Please complete the captcha</ErrorMessage>}
                  </div>
                  {/* <Button
                    hasErrors={
                      Object.keys(errors).length !== 0 || Object.keys(touched).length === 0
                    }
                    type="submit"
                  >
                    Request a Demo
                  </Button> */}
                  <ButtonContainer
                    hasErrors={
                      Object.keys(errors).length !== 0 || Object.keys(touched).length === 0
                    }
                  >
                    <Button
                      disabled={
                        Object.keys(errors).length !== 0 || Object.keys(touched).length === 0
                      }
                      text="Request a Quote"
                      colors={['richElectricBlue', 'richElectricBlue']}
                      type="submit"
                    />
                  </ButtonContainer>
                  <Text>
                    Questions? Call <a href="tel:1-888-908-4924">1-888-908-4924</a> or email{' '}
                    <a href="mailto:support@pathful.com">support@pathful.com</a>
                  </Text>
                </FormikForm>
              )}
            </Formik>
            <LineBottom src={RightLine} />
          </FormContainer>
        </Container>
      </ModalContainer>
    </OpacityLayer>
  );
};

interface IProps {
  onClose: () => void;
  onSubmit: (valus: any, captchaValue: string, onSuccess: () => void, onFail: () => void) => void;
  onSuccess: () => void;
  options: any;
}

export default Form;
