export const CONFIG = {
  STRAPI_API_URL: process.env.STRAPI_API_URL || 'https://cms.pathful.com',
};

export const PATHS = {
  INDEX: '/',
  EXPLORE: '/explore',
  CONNECT: '/connect',
  PLANNER: '/planner',
  JUNIOR: 'https://vjsjunior.com',
  TRAINING: '/training',
  CALENDAR: '/calendar',
  MATERIAL: '/blog',
  WEBINAR: '/webinar',
};

export const PRODUCT = {
  JUNIOR_HOME: 'https://vjsjunior.com/',
  JUNIOR_LOGIN: 'https://vjsjunior.com/login/',
  EXPLORE_LOGIN: 'https://virtualjobshadow.com/login/',
  PLANNER_LOGIN: 'https://planner.virtualjobshadow.com/login/',
  CONNECT_LOGIN: 'https://id.nepris.com/Account/Login?app=v5&returnUrl=',
  SIGN_UP: 'https://id.nepris.com/Account/SignUp?returnUrl=%2Fapp%2Fdashboard&app=v5&sd=nepris',
  PARTNERS: 'https://nepris.com/partners/list',
};

export const SOCIAL = {
  CONTACT: 'mailto:info@pathful.com',
  FACEBOOK: 'https://www.facebook.com/PathfulInc/',
  INSTAGRAM: 'https://www.instagram.com/pathfulinc/',
  LINKEDIN: 'https://www.linkedin.com/company/pathful-inc/',
  TWITTER: 'https://twitter.com/pathfulinc',
  YOUTUBE: 'https://www.youtube.com/c/Pathful',
};

export const KNOWN_US = {
  PRIVATE_POLICY: 'https://virtualjobshadow.com/resources/policy/',
  TERMS_AND_CONDITIONS: 'https://virtualjobshadow.com/resources/terms/',
  PRIVATE_POLICY_CONNECT: 'https://nepris.com/privacy',
  TERMS_AND_CONDITIONS_CONNECT: 'https://nepris.com/terms',
};

export const ACTIVE_CAMPAIGN = {
  CONTACT_US: 'https://pathfulinc.ac-page.com/contact-us',
  REQUEST_A_DEMO: 'https://pathfulinc.ac-page.com/request-a-demo',
  PARTNERS: 'https://partner.nepris.com/partners',
};
