import { createGlobalStyle } from 'styled-components';

import CenturyGothicRegular from '@fonts/CenturyGothic-Regular.ttf';
import CenturyGothicBold from '@fonts/CenturyGothic-Bold.ttf';
import CenturyGothicItalic from '@fonts/CenturyGothic-Italic.ttf';
import CenturyGothicBoldItalic from '@fonts/CenturyGothic-Bold-Italic.ttf';
import PoppinsRegular from '@fonts/Poppins-Regular.ttf';


import ULMGroteskRegular from '@fonts/Ulm-Grotesk-Regular.otf';
import ULMGroteskBold from '@fonts/Ulm-Grotesk-Bold.otf';

export const GlobalFonts = createGlobalStyle`

  @font-face {
    font-display: fallback;
    font-family:"Ulm Grotesk";
    src: url(${ULMGroteskRegular}) format("opentype");
    font-display: block;
    font-weight: normal;
  };

  @font-face {
    font-display: fallback;
    font-family:"Ulm Grotesk";
    font-weight: bold;
    src: url(${ULMGroteskBold}) format("opentype");
    font-display: block;
  };

  @font-face {
    font-display: fallback;
    font-family:"Century Gothic";
    src: url(${CenturyGothicRegular}) format("truetype");
    font-display: block;
    font-weight: normal;
  };

  @font-face {
    font-display: fallback;
    font-family:"Century Gothic";
    src: url(${CenturyGothicBold}) format("truetype");
    font-display: block;
    font-weight: bold;
  };

  @font-face {
    font-display: fallback;
    font-family:"Century Gothic";
    src: url(${CenturyGothicItalic}) format("truetype");
    font-display: block;
    font-style: italic;
  };

  @font-face {
    font-display: fallback;
    font-family:"Century Gothic";
    src: url(${CenturyGothicBoldItalic}) format("truetype");
    font-display: block;
    font-weight: bold;
    font-style: italic;
  };

  @font-face {
    font-display: fallback;
    font-family:"Poppins";
    src: url(${PoppinsRegular}) format("truetype");
    font-display: block;
    font-weight: normal;
  };
`;

export const FONT_FAMILY = {
  ULM_GROTESK: 'font-family: "Ulm Grotesk";',
  CENTURY_GOTHIC: 'font-family: "Century Gothic";',
};
