import { COLORS } from '@global/styles/colors';
import MarkdownView from 'react-showdown';
import React from 'react';
import { MarkdownProps } from './markdown.types';
import { MarkdownStyles } from './markdown.styles';

export const singleLineExtension = {
  type: 'lang',
  regex: /\n\n/g,
  replace: ' ',
};
export const strikethroughExtension = {
  type: 'lang',
  regex: /~(.*)~/g,
  replace: '<s>$1</s>',
};

export const smallFormatExtension = {
  type: 'lang',
  regex: /\^(.*?)\^/g,
  replace: '<small>$1</small>',
};

export const spanNowrapFormatExtension = {
  type: 'lang',
  regex: /\[\[(.*?)\]\]/g,
  replace: '<span class="nospace">$1</span>',
};

export const spanNowrapFormatExtensionRichText = {
  type: 'lang',
  regex: /\\\[\\\[(.*?)\\\]\\\]/g,
  replace: '<span class="nospace">$1</span>',
};

export const colorTextExtension = {
  type: 'lang',
  regex: /\^\^#([0-9a-fA-F]{6})\^\^/g,
  replace: '<span style="color: #$1;">',
};

export const colorTextCheckEditorExtension = {
  type: 'lang',
  regex: /\[#([0-9a-fA-F]{6})](.*?)\[\/#]/g,
  replace: '<span style="color: #$1;">$2</span>',
};

export const colorTextCheckEditorExtensionRichText = {
  type: 'lang',
  regex: /\\\[#([0-9a-fA-F]{6})\\](.*?)\\\[\/#\\]/g,
  replace: '<span style="color: #$1;">$2</span>',
};

export const lineBreakExtension = {
  type: 'lang',
  regex: /\[([a-zA-z*]{1})\]/g,
  replace: '<br class="break-$1"/>',
};

export const lineBreakExtensionRichText = {
  type: 'lang',
  regex: /\\\[([a-zA-z*]{1})\\\]/g,
  replace: '<br class="break-$1"/>',
};

export const redirectEditorExtension = {
  type: 'lang',
  regex: /\[href:(.*?) color:(.*?)](.*?)\[\/href]/g,
  replace: '<a href="$1" style="color: $2; text-decoration:underline; outline: none;">$3</a>',
};

export const italicEditorExtension = {
  type: 'lang',
  regex: /_(.*?)_/g,
  replace: '<em style="font-style: italic;">$1</em>',
};

export const underlineEditorExtensionRichText = {
  type: 'lang',
  regex: /\\<u>(.*?)\\<\/u>/g,
  replace: '<span style="text-decoration: underline; color: #00A8CF;">$1</span>',
};

const MarkdownParser = ({ data, listPointColor, color }: MarkdownProps) => {
  return (
    <MarkdownStyles
      listPointColor={(listPointColor && COLORS[listPointColor]) || listPointColor}
      color={(color && COLORS[color]) || color}
    >
      <MarkdownView
        markdown={data.content}
        extensions={[
          colorTextCheckEditorExtension,
          colorTextCheckEditorExtensionRichText,
          colorTextExtension,
          strikethroughExtension,
          smallFormatExtension,
          spanNowrapFormatExtension,
          spanNowrapFormatExtensionRichText,
          redirectEditorExtension,
          lineBreakExtension,
          lineBreakExtensionRichText,
          italicEditorExtension,
          underlineEditorExtensionRichText,
        ]}
      />
    </MarkdownStyles>
  );
};

export default MarkdownParser;
