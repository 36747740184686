import React from 'react';

import { Container, OpacityLayer } from '@components/Modals/modalStyles';

import { LottiePlayer } from '@components/LottiePlayer';
import {
  SuccesContainer,
  SuccessTitle,
  TopCircle,
  BottomCircle,
  Button,
} from './SuccesModal.styles';

import CircleTop from './assets/circleTop.svg';
import CircleBottom from './assets/circleBottom.svg';

// animations
import checkAnimation from './assets/check.json';

const SuccesModal = ({ onClose }: IProps) => {
  return (
    <OpacityLayer>
      <Container>
        <SuccesContainer>
          <TopCircle src={CircleTop} />
          <LottiePlayer animationData={checkAnimation} />
          <SuccessTitle>Form Successfully Submitted</SuccessTitle>
          <Button onClick={onClose}>Close</Button>
          <BottomCircle src={CircleBottom} />
        </SuccesContainer>
      </Container>
    </OpacityLayer>
  );
};

interface IProps {
  onClose: () => void;
}

export default SuccesModal;
