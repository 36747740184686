import React from 'react';
import ReactPlayer from 'react-player';

import { Container, OpacityLayer } from '@components/Modals/modalStyles';

import { VideoSection, VideoContainer } from './VideoModal.styles';

const VideoModal = ({ onClose, options }: IProps) => {
  return (
    <OpacityLayer onClick={onClose}>
      <Container>
        <VideoSection>
          <VideoContainer>
            <ReactPlayer
              url={options.url}
              className="react-player"
              playing
              width="100%"
              height="100%"
              controls
              loop={false}
              // playin = {false}
              // light={Test}
              // playIcon={<img src={StartIcon}/>}
            />
          </VideoContainer>
        </VideoSection>
      </Container>
    </OpacityLayer>
  );
};

interface IProps {
  onClose: () => void;
  options: any;
}

export default VideoModal;
