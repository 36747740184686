import styled from 'styled-components';
import { TABLET_992 } from '@global/styles/sizes';

export const LottieContainer = styled.div<ILottieContainerProps>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};

  @media (max-width: ${TABLET_992}px) {
    height: ${({ heightMobile }) => heightMobile};
  }
`;

export const SegmentedLottieContainer = styled.div<ILottieContainerProps>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`;
interface ILottieContainerProps {
  height: string;
  heightMobile: string;
  width: string;
}
