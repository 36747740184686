import axios, { AxiosError, AxiosResponse } from 'axios';

const api = axios.create({
  withCredentials: true,
});

const errorResponse = (error: AxiosError) => {
  const response: AxiosResponse | undefined = error?.response;
  const message = response?.data?.message || error.response?.data;
  const responseError = {
    message,
    statusCode: response?.status,
  };
  return responseError;
};

export const get = async (url: string, headers = {}): Promise<AxiosResponse> => {
  try {
    const options = { ...headers };
    const response = await api.get(url, options);
    return response;
  } catch (error: any) {
    throw errorResponse(error);
  }
};

export const post = async (url: string, params = {}, headers = {}): Promise<AxiosResponse> => {
  try {
    const options = { ...headers };
    const response = await api.post(url, params, options);
    return response;
  } catch (error: any) {
    throw errorResponse(error);
  }
};

export const put = async (url: string, params = {}, headers = {}): Promise<AxiosResponse> => {
  try {
    const options = { ...headers };
    const response = await api.put(url, params, options);
    return response;
  } catch (error: any) {
    throw errorResponse(error);
  }
};

export const patch = async (url: string, params = {}, headers = {}): Promise<AxiosResponse> => {
  try {
    const options = { ...headers };
    const response = await api.patch(url, params, options);
    return response;
  } catch (error: any) {
    throw errorResponse(error);
  }
};
